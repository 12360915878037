<template>
<div id="main">

   <open-menu> </open-menu>
   <left-header :imgProps="profilePicture">  </left-header>

   <router-view v-slot="{ Component } ">
     <transition 
      enter-active-class="animate__animated animate__fadeInDown"
      leave-active-class="animate__animated animate__fadeOutDown"
      mode="out-in">
     <component :is="Component" />
     </transition>
     </router-view>
   
</div>

</template>

<script>

import LeftHeader from './components/LeftHeader';
import OpenMenu from './components/OpenMenu';



export default {

  

  data() {
        return {
           profilePicture: require('./assets/pp.jpg'),
           aboutPp: require('./assets/about-pp.png'),
          
        }
    },

    
   
  components: {
    leftHeader : LeftHeader, 
    OpenMenu
  }

}
</script>

<style>



:root {
  --colorBack: #231e23;
  --otherCol: #39a6a3;
  --borderCol: hsl(178, 49%, 29%);
}

::-moz-selection { /* Code for Firefox */
  color: #fff;
  background: var(--otherCol);
}

::selection {
  color: #fff;
  background: var(--otherCol);
}


* {
  padding:0;
  margin: 0;
}

body {
  font-family: 'Poppins', sans-serif;
  display: flex;
  height: 100vh;
  width: 100vw;
  max-width: 100vw;
  background-color: var(--colorBack);
  overflow-x: hidden;

}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: var(--colorBack);
}

::-webkit-scrollbar-thumb {
  background: var(--otherCol);
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}


#main {
  display: flex;
  flex-direction: row3-
}

.fade-enter-from,
.fade-leave-to {
  opacity:0;

}

.fade-enter-active,
.fade-leave-active {

  transition: opacity 0.5s ease-out;
}



.animate__delay-1 {
    animation-delay: 0.4s;
}

.animate__delay-2 {
    animation-delay: 0.8s;
}

.animate__delay-3 {
    animation-delay: 1.2s;
}

.animate__delay-4 {
    animation-delay: 1.6s;
}

.animate__delay-5 {
    animation-delay: 2s;
}

.animate__delay-6 {
    animation-delay: 2.4s;
}

.animate__delay-7 {
    animation-delay: 2.8s;
}

.animate__delay-8 {
    animation-delay: 3.2s;
}

.animate__delay-9 {
    animation-delay: 3.6s;
}


@media screen and (max-width: 750px) {



  
}


</style>
