<template>
<div class="about-main">
    <div class="about">
        <div class="dis_flex">
            <div>
    <h1 class="tit">My<span> Experience </span></h1> 
         <div class="dis_flex work">
            <div class="graph">
                <div class="round"><i class="fas fa-code-branch"></i></div>
                <div class="roundLine"></div>
            </div>
            <div class="textWork">
                <h3 class="animate__animated animate__fadeInLeft animate__delay-1 "> Dec 2019 - Present</h3>
                <h2 class="animate__animated animate__fadeInLeft animate__delay-2 ">HSBC HQ, Istanbul, Turkey</h2>
                <p class="animate__animated animate__fadeInLeft animate__delay-3 ">Data Platforms Senior System Consultant</p>
                <p class="animate__animated animate__fadeInLeft animate__delay-4 ">• Database Management (planning, designing, installing, configuring, administrating, monitoring, supporting and reporting) in order to
support all services that use database, considering availability, reliability, security and performance of the services.</p>
                <p class="animate__animated animate__fadeInLeft animate__delay-5 ">• Achieved cost avoidance of approximately 500K USD by regaining ¼ size of total disk estate of HSBC Turkey within the scope of
database archiving work.</p>
                <p class="animate__animated animate__fadeInLeft animate__delay-6 ">• Effectively run the bank from DR site for a week and return to PROD site within HSBC Turkey, which is the only bank in the
country that performs disaster recovery exercise by running PROD services on DR site, including core banking systems and all
supporting services.</p>
                <p class="animate__animated animate__fadeInLeft animate__delay-7 ">• Successfully completed various improvements for better monitoring of SQL Server environments. </p>
                <p class="animate__animated animate__fadeInLeft animate__delay-8">• Performed and constantly performing performance tuning work with critical results.</p>
                <p class="animate__animated animate__fadeInLeft animate__delay-9">• Completed Türkiye Core Banking SQL Server Evergreening project ,covering all core banking databases of the country, with zero
incidents which is a testament to the meticulous planning, efficient project management, flawless execution, adequate testing,
and an unwavering focus on the finer details.</p>
            
            
            
            </div>
        </div>

         <div class="dis_flex work">
            <div class="graph">
                <div class="round"><i class="fas fa-code-branch"></i></div>
                <div class="roundLine"></div>
            </div>
            <div class="textWork">
                <h3 class="animate__animated animate__fadeInLeft animate__delay-1 ">Sept 2015 - Mar 2017</h3>
                <h2 class="animate__animated animate__fadeInLeft animate__delay-2 ">SabancıDx (Bimsa), Istanbul, Turkey</h2>
                <p class="animate__animated animate__fadeInLeft animate__delay-3 ">Department Manager – Middleware Services</p>
                <p class="animate__animated animate__fadeInLeft animate__delay-4 ">• Oversee offshore and onshore teams that support various database and middleware platforms and the associated applications in a
24x7 stacked environment. These include SAP Basis, Websphere, Weblogic, Apache, IIS, SQL Server, Oracle, and Sybase.</p>
                <p class="animate__animated animate__fadeInLeft animate__delay-5 ">• Reduced costs and maintained profitability by managing the department's operational, budgetary, and financial responsibilities.</p>
                <p class="animate__animated animate__fadeInLeft animate__delay-6 ">• Increased team performance by providing training, coaching and mentoring to improve morale, communications, and teamwork.</p>
                <p class="animate__animated animate__fadeInLeft animate__delay-7 ">• • Maximized efficiency and productivity by implementing solutions to improve processes continuously.</p>
              
            
            
            
            </div>
        </div>

              
            
            
            
            </div>
        </div>


        
         <div class="dis_flex work">
            <div class="graph">
                <div class="round"><i class="fas fa-code-branch"></i></div>
                <div class="roundLine"></div>
            </div>
            <div class="textWork">
                <h3 class="animate__animated animate__fadeInLeft animate__delay-1 ">Dec 2012 - Sept 2015</h3>
                <h2 class="animate__animated animate__fadeInLeft animate__delay-2 ">Intertech, Denizbank, Istanbul, Turkey </h2>
                <p class="animate__animated animate__fadeInLeft animate__delay-3 ">Department Manager – Database Administration</p>
                <p class="animate__animated animate__fadeInLeft animate__delay-4 ">• Supervised Database Administration and DWH Application Management teams to oversee all database systems of Denizbank Financial
Services Group and multiple independent banks. Led and directed Database Administration Department, mainly focused on core
banking and supporting SQL Server systems. DWH Application Management teams oversaw data warehouse systems, ETL design and
operation, INFORMATICA and SSIS, Parallel Data Warehouse Appliance, and SQL Server.</p>
                <p class="animate__animated animate__fadeInLeft animate__delay-5 ">• Led a team to manage databases of up to 10TB with a workload of 30K batches per second in accordance with VLDB and multi-TB
database concepts and compliance.</p>
                <p class="animate__animated animate__fadeInLeft animate__delay-6 ">• Improved efficiency by increasing workload from 15K to 30K batches per second, reducing CPU usage from 70% to 40% and
EOD (End of Day) processing time from 8 to 2.5 hours.</p>
                <p class="animate__animated animate__fadeInLeft animate__delay-7 ">• Ensured client satisfaction by consulting with customers to determine individual needs in order to conduct business analysis and
continuity planning, database systems design, and version upgrades.</p>
                <p class="animate__animated animate__fadeInLeft animate__delay-8 ">•  Improved system performance by supervising daily database operations conducting performance monitoring and tuning.</p>
                <p class="animate__animated animate__fadeInLeft animate__delay-9 ">•  Mitigated risks, increased security, and reduced downtime by facilitating ongoing SQL server monitoring and performing periodic
maintenance tasks, repairs, and troubleshooting.</p>
              
            
            
            
            </div>
        </div>

        
         <div class="dis_flex work">
            <div class="graph">
                <div class="round"><i class="fas fa-code-branch"></i></div>
                <div class="roundLine"></div>
            </div>
            <div class="textWork">
                <h3 class="animate__animated animate__fadeInLeft animate__delay-1 ">Mar 2011 - Dec 2012</h3>
                <h2 class="animate__animated animate__fadeInLeft animate__delay-2 ">Microsoft, Istanbul, Turkey</h2>
                <p class="animate__animated animate__fadeInLeft animate__delay-3 ">Senior Premier Field Engineer</p>
                <p class="animate__animated animate__fadeInLeft animate__delay-4 ">• Delivered on-site technical support solutions to customers while ensuring satisfaction with Microsoft software on the client and server.</p>
                <p class="animate__animated animate__fadeInLeft animate__delay-5 ">•  Ensured the highest level of customer service as the primary onsite technical contact for the MEA region, providing advanced technical troubleshooting support and problem resolution for Product Support Services and corporate customers.</p>
                <p class="animate__animated animate__fadeInLeft animate__delay-6 ">• Achieved the Microsoft CSS Global Awards, Q2, 2012 and the Microsoft GPGP Award (Great People, Great Performance), which recognizes individuals worldwide for making extraordinary contributions to the Services business. These contributions included translating the company’s CPE, People, Market Share, and Operational Excellence goals into real-world successes that showcase the power of Services and drive the right behaviors to support business and individual growth.
</p>
        
       


      </div>
      
       

      </div>


      
         <div class="dis_flex work">
            <div class="graph">
                <div class="round"><i class="fas fa-code-branch"></i></div>
                <div class="roundLine"></div>
            </div>
            <div class="textWork">
                <h3>Feb 2010 - Feb 2011</h3>
                <h2>Ibtech, Finansbank, Istanbul, Turkey</h2>
                <p>Senior Designer - Database</p>
                <p>• Designed, operated, and maintained Microsoft SQL and Oracle servers in Finansbank as a member of the Infrastructure Support
Databases Team.</p>
                <p>• Increased efficiency, mitigated risks, and ensured client satisfaction through strategic planning and implementing solutions to continuously improve processes.</p>

        
       


      </div>
      
       

      </div>

      
         <div class="dis_flex work">
            <div class="graph">
                <div class="round"><i class="fas fa-code-branch"></i></div>
                <div class="roundLine"></div>
            </div>
            <div class="textWork">
                <h3>Nov 2002 - Feb 2010</h3>
                <h2>Garanti Technology, Garanti Bank, Istanbul, Turkey</h2>
                <p>Senior System Software Specialist</p>
                <p>• Designed and maintained approximately 600 Microsoft SQL and Oracle servers in Dogus Group as a member of the Open Systems
Databases Team. Actively tested feature sets of Yukon and provided feedback to product groups in Microsoft from the beginning of
Beta2 phase of the product as a Yukon SQL server 2005 TAP team member.</p>
                <p>• Increased efficiency and reliability by consolidating more than 20 SQL 2000 and SQL 7.0 servers and 120 databases in the first phase of the project as a member of the Consolidation Team, responsible for SQL server consolidation in 16-way IBM x-server 445 environment.</p>
                <p>• Delivered insurance applications in a highly available, reliable, and secure environment by designing and implementing a terminal server project, which included developing and maintaining more than 400 Active Directory Servers in Garanti Bank branch offices.</p>

        
       


      </div>
      
       

      </div>
    
    

      </div>

      
    </div>
    
  
</template>


<script>

export default {
    props: ["imgProps"],
}

</script>

<style scoped>



.dis_flex {
    display: flex;
    justify-content:space-evenly;
}

.about-main {
    width: calc(100vw - 251px);
    background-color: var(--colorBack);
    height: 100vh;
    display:flex;
    flex-direction: row;
    overflow: scroll;
    overflow-x: hidden;
}

.about {
    display: flex;
    flex-direction: column;
   
    padding-top: 1rem;
    width: calc(100vw - 251px);
    background-color: #231e23;
}

p {
    padding: 1rem 6rem;
    color: #deeeea;
}

h1 {
 
    color:#deeeea;
    font-size: 2rem;
}

h1 span {
    font-weight: 700;
    color: var(--otherCol);
}


.dis_block {
    display: flex;
    flex-direction:column;
}

.round {
    width: 3.5rem;
    height: 3.5rem;
    background-color: var(--otherCol);
    border-radius: 100%;
    z-index: 1;

}

.dis_flex .tit {
    padding-left: 1rem;
}

.roundLine {
    height: calc(100% - 5rem);
    width: 1px;
    z-index: -1;
    background-color: var(--borderCol);
    margin-left: 50%;
    transform: translate(-50%);
}

.round i {
    color: #deeeea;
    font-size: 2rem;
    margin: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
}

.textWork h3 {
    font-size: 0.8rem;
    color: #deeeea;
    background-color: rgba(0,0,0,0.3);
    width: 9rem;
    border-radius: 1rem;
    padding: 0.2rem;
    padding-left:0.7rem;
}

.textWork h2 {
    color: #fff;
}

.textWork p {
    padding: 0;
    margin: 0;
}

.textWork {
    padding-left: 0.6rem;
    padding-top: 0.6rem;
}

.work {
    padding: 1rem 2rem;

}

@media screen and (max-width: 750px) {
    .about-main {
    width: 100vw;
    height: auto;
    padding-top: 7rem;

}
    .about {
    padding-top: 1rem;
    width: 100vw;
    background-color: #231e23;
}
}



</style> 