<template>
<div class="left">


<img :src="imgProps" alt="">



<ul>
  <li> <router-link to="/" >   <i class="fas fa-address-card"></i> About</router-link> </li> 
   <li> <router-link to="/services" >    <i class="fas fa-briefcase"></i> Services</router-link> </li> 
    <li> <router-link to="/experience" >    <i class="fas fa-briefcase"></i> Experience</router-link> </li> 
   <li> <router-link to="/contact"> <i class="fas fa-envelope"></i> Contact </router-link> </li>
</ul>

<div class="soc">
    <ul>
        <a href="https://tr.linkedin.com/in/hmsubasi"> <li>  <i class="fab fa-linkedin-in"></i>  </li> </a>
       <a href="https://api.whatsapp.com/send?phone=905326148813"> <li>   <i class="fab fa-whatsapp"></i>   </li></a>
    </ul>
</div>



</div>
</template>

<script>

export default {
    props: ["imgProps"],

    data() {
        return {
            
        }
    },
    methods: {
        openMenu() {
            document.querySelector(".left").style.width = "100vw";
        }
    }
}

</script>


<style scoped>

.soc ul {
    display: flex;
    padding-top: 1rem;
    
}

.soc ul li {
    margin: 0 0.5rem;
    padding: 0.7rem;
    border: 1px solid var(--borderCol);
    border-radius: 0.3rem;
    z-index: 1;
    position: relative;
}



.soc ul li:before {
    position: absolute;
    background-color: var(--otherCol);
    left: 0;
    z-index: -1;
    transition: .7s;
    bottom: 0;
    width: 100%;
    height: 0;
    opacity: 0;
   
    content: '';
}

.soc ul li:hover::before {
    height: 100%;
   
    opacity: 1;
    
}


.left {
   transition: 2s ease;
   display: flex;
   flex-direction: column;
   height: 100vh;
   width: 250px;
   max-width: 250px;
   align-items: center;
   text-align: center;
   justify-content: center;
   background-color: var(--colorBack);
   border-right: 1px solid var(--borderCol);
}



ul li {
    
    list-style: none;
    padding: 0.2rem;
}

ul li a {
    font-weight: 400;
    width: 300px;
    color: #deeeea;
    font-size: 1rem;
    font-weight: 500;
    text-decoration: none;
}

img {
    border-radius: 50%;
    border: 0.33rem solid var(--borderCol);
    width:12rem;
    height: auto;
    margin-bottom: 1rem;
}

ul li i {
    color: #deeeea;
    font-size: 1rem;
    background: transparent;
    z-index: 5;
    
}


@media screen and (max-width: 750px) {

.left {
  display: none;
  
}

  
}


</style>