import { createWebHistory, createRouter } from "vue-router";
import About from "@/components/About.vue";
import Experience from "@/components/Experience.vue";
import Contact from "@/components/Contact";
import Services from "@/components/Services"
const routes = [
  {
    path: "/",
    name: "About",
    component: About,
  },
  {
    path: "/experience",
    name: "Experience",
    component: Experience,
  },
  {
    path: "/services",
    name: "Services",
    component: Services,
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  }
  
];
const router = createRouter({
  history: createWebHistory(),
  routes,
});
export default router;