<template>

<div class="about-main">
    <div class="about">
      

    <div class="circles">

   <div class="circle-p animate__animated animate__fadeInRight animate__delay-1">
<circle-progress
    :is-shadow="true"
    :shadow="{
        inset: true,
        vertical: 2,
        horizontal: 2,
       
        blur: 4,
        opacity: .4,
        color: '#000000'
    }"
    :background = 'red'
    :fill-color	= color
    :percent="perc85"
    :transition="500"
/>
 <p>Database Administration Management </p>
</div>

<div class="circle-p animate__animated animate__fadeInRight animate__delay-2 ">
<circle-progress
    :is-shadow="true"
    :shadow="{
        inset: true,
        vertical: 2,
        horizontal: 2,
       
        blur: 4,
        opacity: .4,
        color: color
    }"
    :fill-color	= color
    :percent="perc75"
    :background= color
    :transition="500"
/>
 <p>Project Management </p>
</div>

<div class="circle-p animate__animated animate__fadeInRight animate__delay-3">
<circle-progress
    :is-shadow="true"
    :shadow="{
        inset: true,
        vertical: 2,
        horizontal: 2,
       
        blur: 4,
        opacity: .4,
        color: color
    }"
    :fill-color	= color
    :percent="perc45"
    :transition="500"
/>
 <p>Applications Management </p>
</div>

<div class="circle-p animate__animated animate__fadeInRight animate__delay-4">
<circle-progress
    :is-shadow="true"
    :shadow="{
        inset: true,
        vertical: 2,
        horizontal: 2,
       
        blur: 4,
        opacity: .4,
        color: color
    }"
    :fill-color	= color
    :percent="perc95"
    :transition="500"
/>
 <p>Technical Team Leadership </p>
</div>

<div class="circle-p animate__animated animate__fadeInRight animate__delay-5">
<circle-progress
    :is-shadow="true"
    :shadow="{
        inset: true,
        vertical: 2,
        horizontal: 2,
       
        blur: 4,
        opacity: .4,
        color: color
    }"
    :fill-color	= color
    :percent="perc90"
    :transition="500"
/>
 <p>Analytical Problem-Solving </p>
</div>

<div class="circle-p animate__animated animate__fadeInRight animate__delay-6">
<circle-progress
    :is-shadow="true"
    :shadow="{
        inset: true,
        vertical: 2,
        horizontal: 2,
       
        blur: 4,
        opacity: .4,
        color: color
    }"
    :fill-color	= color
    :percent= perc85
    :transition="500"
/>
 <p>Operations Management </p>
</div>


</div>



      </div>

      
    </div>
</template>






<script>

import CircleProgress from "vue3-circle-progress";


export default {
    data() {
        return {
           perc85 : 0,
           perc75 : 0,
           perc40 : 0,
           perc95 : 0,
           perc45 : 0,
           perc90 : 0,
        }
    },
    components: {
     CircleProgress
    },

    methods: {

        showAlert() {
            alert("alert");
        },

        changeValue() {
        this.perc85 = 85;
        this.perc75 = 75;
        this.perc40 = 40;
        this.perc45 = 45;
        this.perc95 = 95;
        this.perc90 = 90;
        }

    },

    created() {
          setTimeout(()=>{
        this.changeValue()
         }, 3000)
         
    }

    
}

</script>



<style scoped>

.circles {
    display: flex;
    width: calc(100vw - 251px);
    flex-wrap: wrap;
    justify-content: space-between;
}


.about-main {
    display: flex;
    width: calc(100vw - 251px);
    justify-content: center;
    color:  #fff;
    align-items: center;
    text-align: center;
}


.circle-p {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding-bottom: 2rem;
    width: 33.33%;
    
}

.circle-p p {
    padding-top: 0.4rem;
}




@media screen and (max-width: 750px) {
    .circles {
    width: 100vw;
}

.about-main {
    padding-top: 7rem;
    width: 100vw;
}

.circle-p {
    
    width: 100%;
    
}
}



</style>


