<template>
<div class="about-main">
    <div class="about">
    <h1 class="animate__animated animate__flipInX animate__delay-1"><span class="animate__animated animate__zoomInUp animate__delay-2"> Hakan Murat Subaşı </span></h1> 
    <p class="animate__animated animate__zoomInDown animate__delay-2">An accomplished Senior Database and Software Application Department Lead with more than 20 years of experience in SQL database management, field engineering, software application design and implementation, and technical team leadership.
<br> <br>
 strategic planner who implements solutions to increase team efficiency and productivity while mitigating potential risks, increasing security, and reducing system downtime. A skilled relationship builder who consults with clients to identify individual needs in order to offer the highest quality service and ensure business development and market expansion.
<br> <br>
An articulate and respected leader who motivates team members to consistently exceed set goals in order to further the mission of the organization.

 </p> <br>

 <h4 class="animate__animated animate__flipInX animate__delay-3"><span class="animate__animated animate__zoomInUp animate__delay-3"> DBA & Software Application Manager </span></h4> 

 <p class="animate__animated animate__flipInX animate__delay-4"> 
    Highly analytical and solution-focused Senior SQL Server Database & Software Application Lead with over 25 years of experience in SQL
database management, field engineering, software application design and implementation, and technical team leadership. A strategic
planner who implements solutions to mitigate potential risks, increase security, and reduce system downtime while increasing team
efficiency and productivity. Adept at building and maintaining relationships with clients to identify their needs in order to offer the best
possible service and ensure business development and market expansion. Have an excellent project management capability with
outstanding leadership skills to exceed business goals and further the mission of the organization.
 </p>

 <h4 class="animate__animated animate__flipInX animate__delay-5"><span class="animate__animated animate__zoomInUp animate__delay-5"> Qualification Highlights & Awards </span></h4> 

 <p class="animate__animated animate__flipInX animate__delay-6"> 
• Achieved the ‘Microsoft CSS Global Awards’, Q2, 2012 and the ‘Microsoft GPGP Award’ (Great People, Great Performance), which
recognizes individuals worldwide for making extraordinary contributions to the Services business. <br>
• Possess in-depth experience in banking, finance, financial services, fin-tech and technology industries. <br>
• Proven record of leading Disaster Recovery (DR), Business Continuity Management (BCM), Replication and Change Data Capture
Technologies, Bulletproof SQL Server VLDB performance tuning experience with multiple success stories and Always On/Windows
Failover Clustering. <br>
• Vast understanding of technological tools and systems involving SQL Server Database Administration 7.0 2000/2005/2008/ 2012/
2014/ 2016/2017/ 2019 and SSIS, SSRS, SSAS. <br>
• Have an advanced level of English and a native level of Turkish. </p>
     

      
    </div>
    
    

</div>
</template>


<script>

export default {
    props: ["imgProps"],
}

</script>

<style scoped>

.dis_flex {
    display: flex;
}

.about-main {
    width: calc(100vw - 251px);
    background-color: var(--colorBack);
    height: 100vh;
    display:flex;
    flex-direction: row;
    overflow: scroll;
    overflow-x: hidden;
}

.about {
    height: max-content;
    margin-top: 24px;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: calc(100vw - 251px);
    background-color: #231e23;
  
}

p {
    padding: 1rem 6rem;
    color: #deeeea;
}

h1 {
    color:#deeeea;
    font-size: 2rem;
}

h1 span {
    font-weight: 700;
    color: var(--otherCol);
}

h4 span {
    font-weight: 700;
    color: var(--otherCol);
}

@media screen and (max-width: 750px) {

    .about-main {
    height: auto;
    width: 100vw;
    background-color: var(--colorBack);
    padding-top: 5.5rem;

}

.about {
   padding-top: 1rem;
   width: 100vw;
   
}

p {
    padding: 1rem 2rem 0.7rem 2rem;
    text-align: justify;
}

h1 {
    text-align: center;
}
  
}





</style> 